import "./CheckEmail.scss";
import kiteIcon from "../../assets/icons/kiteIcon.svg";
import { useNavigate } from "react-router-dom";

interface userMail {
  userMail: string;
  title: string;
  succesText?: string;
  navigateToLogin?: () => void;
}

const CheckEmail = ({
  navigateToLogin,
  userMail,
  title,
  succesText = "Check Your Email",
}: userMail) => {
  const navigate = useNavigate();

  return (
    <div className="email-sent">
      <div className="check-email-title">{title}</div>
      <img src={kiteIcon} width={250} alt="Kite icon" />
      <p className="email-sent-success-text">{succesText}</p>
      <div style={{marginTop: '30px'}} className="go-to-login-wrapper">
        <p style={{ margin: "auto" }}>
          Go to
          <button className="go-to-login-btn" onClick={navigateToLogin}>
            Login
          </button>
        </p>
      </div>
    </div>
  );
};

export default CheckEmail;
