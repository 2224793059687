import "./TranslatorHeader.scss";
import { langsMap } from "../../data/deeplapi";
import { HeaderProps } from "../../models/header.interface";
import switchLangIcon from '../../assets/icons/switchLangIcon.svg';
import { useRef, useState } from "react";

function TranslatorHeader({ handleSourceVal, handleTargetVal }: HeaderProps) {

  const srcRef = useRef<HTMLSelectElement>(null);
  const targetRef = useRef<HTMLSelectElement>(null);

  const switchLangHandler = () => {
    const srcLang = srcRef.current?.value || 'de';
    const targetLang = targetRef.current?.value || 'en-GB';

    const event = new Event('change', {
      bubbles: true,
      cancelable: true
    })

    if (srcRef.current) {
      srcRef.current.value = targetLang;
      srcRef.current.dispatchEvent(event);
    }
    if (targetRef.current) {
      targetRef.current.value = srcLang;
      targetRef.current.dispatchEvent(event);
    }

  }

  const mappedLangs = [...langsMap.entries()].map(([key, value], index) => {
    return (
      <option key={index} value={key}>
        {value}
      </option>
    );
  });

  return (
    <div className="header-wrapper">
      <div className="translate-from">
        <select ref={srcRef} className="source-lang" defaultValue={'de'} onChange={handleSourceVal} name="source-lang" id="source-lang" >
          {mappedLangs}
        </select>
      </div>
      <div className="switch-lang-container" onClick={switchLangHandler}><img src={switchLangIcon} alt="Switch languages" /></div>
      <div className="translate-to">
        <select ref={targetRef} className="target-lang" defaultValue={'en-GB'} onChange={handleTargetVal} name="target-lang" id="target-lang">
          {mappedLangs}
        </select>
      
      </div>
    </div>
  );
}

export default TranslatorHeader;
