import React, { createContext, useContext, useEffect, useState } from 'react';

export interface UserData {
    userEmail: string;
    userId: string;
    isAuthenticated: boolean;
    role: string;
    message: string;
    sessionExpiry: Date;
}

interface UserContextType {
    userData: UserData | null;
    setUserData?: (user: UserData | null) => void;
    logoutUser: () => void;
    loginUser: (userData: UserData) => void;
}


export const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [userData, setUserData] = useState<UserData | null>(() => {
        const user = localStorage.getItem('user');
        return user ? JSON.parse(user) : null;
    });

    useEffect(() => {
        if (userData) {
            localStorage.setItem('user', JSON.stringify(userData));
        } else {
            localStorage.removeItem('user');
        }
    }, [userData]);

    const logoutUser = () => {
        setUserData(null);
    }

    const loginUser = (userData: UserData) => {
        setUserData(userData);
    }

    return (
        <UserContext.Provider value={{ userData, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserData = () => {
    useContext(UserContext);
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};