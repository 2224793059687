import {Routes, Route, useNavigate} from "react-router-dom";

import './App.scss';
import Translator from './pages/translator/Translator';
import Login from './pages/login/Login';
import PrivateRoutes from "./guards/PrivateRoutes";
import CreatePassword from "./pages/create-password/CreatePassword";
import Signup from "./pages/signup/Signup";
import ConfirmRegistration from "./pages/confirm-registration/ConfirmRegistration";
import TranslatorDashboard from "./pages/translator-dashboard/TranslatorDashboard";
import { useUserData } from "./hooks/userData";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();
  const {userData, logoutUser} = useUserData();

  const autoLogOut = () => {
    logoutUser();
    navigate('/login');
  }

  const isSessionExpired = (expiry: Date) => {
    const now = new Date();
    const expiryDate = new Date(expiry);
    return now >= expiryDate;
  }

  useEffect(() => {
    let logoutInterval: any;
    if (userData && userData?.sessionExpiry) {
      logoutInterval = setInterval(() => {
        if (isSessionExpired(userData?.sessionExpiry)) {
          autoLogOut();
        }
      },  1 * 60 * 1000); //check every minute
    }
    
    return () => {
      if (logoutInterval) {
        clearTimeout(logoutInterval);
      }
    };
  }, [userData, logoutUser])

  return (
      <div className="App">
        <Routes>
          <Route element={<PrivateRoutes />}>
              <Route element={<Translator/>} path="/"/>
              <Route element={<TranslatorDashboard/>} path="/dashboard"/>
          </Route>
          <Route element={<Login/>} path="/login"/>
          <Route element={<CreatePassword/>} path="/create-password/:userCode"/>
          <Route element={<Signup/>} path="/signup"/>
          <Route element={<ConfirmRegistration/>} path="/confirm-registration/:userCode"/>
        </Routes>
      </div>
  );
}

export default App;
