import React, { useState } from "react";
import Footer from "../../components/layout/footer/Footer";
import "./CreatePassword.scss";
import { Controller, useForm } from "react-hook-form";
import thumbUpIcon from "../../assets/icons/thumbUpIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { passwordChange } from "../../services/auth-service";
import { toast } from "react-toastify";
import PasswordToggle from "../../components/password-toggle/PasswordToggle";
import { PasswordTooltip } from "../../components/password-tooltip/PasswordTooltip";
import InfoIcon from '@mui/icons-material/Info';

const CreatePassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();
  let pwd = watch("password");
  const [passwordCreated, setPasswordCreated] = useState(false);
  const notify = (message: string) => toast.error(`${message}`, { position: 'top-right' });
  const notifySuccess = (message: string) =>
    toast.success(`${message}`, { position: "top-right" });
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

  const sendPassword = async (data: {[key: string]: any}) => {
    try {
      const changed = await passwordChange(params?.userCode || "" , data?.password);
      setPasswordCreated(true);
    } catch (err: any) {
      console.log(err);
      notify(err?.response?.data?.message || 'Unexpected error occured');
      setPasswordCreated(false);
    }
  };

  const setPasswordState = () => {
    setPasswordToggle((val) => !val);
  }

  const setConfirmPasswordState = () => {
    setConfirmPasswordToggle((val) => !val);
  }

  const onErrors = () => {};

  return (
    <div className="create-password-page">
      <img
        className="overlay-bg"
        src={require("../../assets/deepl-new-background.webp")}
        alt="overlay"
      />
      <div className="create-pass-content-wrapper">
        <div className="page-title">
          <div className="company">ESD</div>
          <div className="title-text">TRANSLATION</div>
          <div className="title-text">PLATFORM</div>
        </div>
        <div className="create-password-form">
        <div className="create-password-title">{passwordCreated ? 'PASSWORD RESET' : 'CREATE PASSWORD'}</div>
          {!passwordCreated ? (
            <form onSubmit={handleSubmit(sendPassword, onErrors)}>
              <div className="form-fields">
                
                <div className="password-wrapper">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <label className="create-password-label" htmlFor="password">
                          Password *
                          <PasswordTooltip
                             title={
                              <React.Fragment>
                                “To create a valid password, you should use at least 6 characters.”
                              </React.Fragment>
                            }
                          >
                            <InfoIcon style={{cursor: 'pointer'}}></InfoIcon>
                          </PasswordTooltip>
                          </label>
                        <div style={{position: 'relative'}}>
                          <input
                            className="password"
                            type={!passwordToggle ? "password" : "text"}
                            {...field}
                            name="password"
                            id="password"
                          />
                           <PasswordToggle passwordToggle={passwordToggle} setPasswordState={setPasswordState} />
                        </div>
                        {fieldState.error && (
                          <p className="validation-error-message">
                            {fieldState?.error?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="confirm-password-wrapper">
                  <Controller
                    name="confirm-password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      minLength: {
                        value: 6,
                        message:
                          "The password should contain at least 6 characters.",
                      },
                      validate: (value) =>
                        value === pwd || "The passwords do not match",
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <label className="create-password-label" htmlFor="confirm-password">Confirm Password *</label>
                        <div style={{position: 'relative'}}>
                          <input
                            className="confirm-password"
                            {...field}
                            type={!confirmPasswordToggle ? "password" : "text"}
                            name="confirm-password"
                            id="confirm-password"
                          />
                          <PasswordToggle passwordToggle={confirmPasswordToggle} setPasswordState={setConfirmPasswordState} />
                        </div>
                        {fieldState.error && (
                          <p className="validation-error-message">
                            {fieldState?.error?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="change-pass-btn-wrapper">
                  <button
                    className="form-button"
                    type="submit"
                    onSubmit={handleSubmit(sendPassword, onErrors)}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
              <p className="password-create-tip">
              To create a valid password, you should use at least 6 characters.
              </p>
            </form>
          ) : (
            <div className="pass-changed-wrapper">
              <img src={thumbUpIcon} width={250} alt="Thumb up icon" />
              <p className="pass-changed-success-text">Successfully changed</p>
              <div className="go-to-login-wrapper" style={{marginTop: '30px'}}>
                Go to 
                <button
                  className="go-to-login-btn"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
              </div>
                
            </div>
          )}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default CreatePassword;
