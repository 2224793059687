import { useEffect, useState } from "react";
import "./DotsLoader.scss";

const DotsLoader = ({textColor}: {textColor: string}) => {
  const dynamicWords = ["Ready", "Listo", "Bereit", "Pronto"];
  const [word, setWord] = useState(dynamicWords[0]);

  useEffect(() => {
    let index = 0;
    let wordInterval = setInterval(() => {
      setWord(() => dynamicWords[index]);
      index++;
      if (index >= dynamicWords.length) index = 0;
    }, 500);
    return () => clearInterval(wordInterval);
  }, []);

  return (
    <div className="loader-wrapper">
      <div className="loader-text">
        <span style={{color: textColor}}>You're almost</span>
        <span style={{ color: "#5891FF" }}>{word}</span>
      </div>
      <div className="bouncing-loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default DotsLoader;
