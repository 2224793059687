import axios from "axios";

export const authenticate = async(usernameOrEmail: string, pass: string) => {
    return await axios.post(process.env.REACT_APP_URL + '/auth' || '', {usernameOrEmail: usernameOrEmail, password: pass}, { withCredentials: true });
}

export const confirmRegistration = async (code: string) => {
    return await axios.post(process.env.REACT_APP_URL + '/confirm-registration', {userCode: code});
}

export const signup = async (data: any) => {
    return await axios.post(process.env.REACT_APP_URL + '/signup', data, {withCredentials: true});
}

export const passwordReset = async (email: string) => {
    return await axios.post(process.env.REACT_APP_URL + '/password-reset', {email: email});
}

export const passwordChange = async (code: string, password: string) => {
    return await axios.post(process.env.REACT_APP_URL + '/password-change', {userCode: code, password: password});
}