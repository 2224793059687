import { useState } from 'react';
import './TranslateFiles.scss';
import pdfIcon from '../../assets/icons/pdfIcon.svg';
import wordIcon from '../../assets/icons/wordIcon.svg';
import ppIcon from '../../assets/icons/powerPointIcon.svg';
import DotsLoader from '../dots-loader/DotsLoader';
import { downloadFileFromDeepl, langsMap, sendForTranslationDoc } from '../../data/deeplapi';

interface TranslateFilesProps {
    langFrom: string;
    langTo: string;
    backToTranslator: any;
}

const TranslateFiles = ({langFrom, langTo, backToTranslator}: TranslateFilesProps) => {
    const [step, setStep] = useState(1); 
    const [isLoading, setIsLoading] = useState(false); 
    const [isFileUploading, setIsFileUploading] = useState(false); 
    const [file, setFile] = useState<File>(); 
    const [translatedFileName, setTranslatedFileName] = useState<string>('');

    const handleFileChange = (event: any) => {
        setIsLoading(true);
        setFile(event.target.files[0]);

        setTimeout(() => {
            setIsLoading(false);
            setStep(2);
        }, 2000);
    }

    const handleTranslateFile = async () => {
        setIsFileUploading(true);
        const form = new FormData();
        form.append('fileUpload', file as File);
        form.append('sourceLang', langFrom);
        form.append('targetLang', langTo);
        try {
            const fileName = await sendForTranslationDoc(form);
            setTranslatedFileName(fileName.data);
            
            setStep(3);
        } catch(err) {
          console.log(err);
        } finally {
            setIsFileUploading(false);
        }
    }

    const getCurrentFileIcon = () => {
        const ext = file?.name.split('.').pop();
        if (ext === 'pdf') {
            return pdfIcon;
        }
        if (ext === 'doc' || ext === 'docx') {
            return wordIcon;
        }
        if (ext === 'ppt' || ext === 'pptx') {
            return ppIcon;
        }
    }

    const handleDownloadFile = async () => {
        try {
            await downloadFileFromDeepl(translatedFileName);
        } catch(err) {
            console.log(err)
        } finally {
           
            setStep(1);
        }

    }

    return (
        <div style={{width: '100%', height: '100%'}}>
            {step === 1 && <div className='choose-file-wrapper'>
                {!isLoading ? ( <>
                <div className='icons-wrapper'>
                    <div>
                        <img height={100} src={pdfIcon} alt="pdf" />
                        <p className='icon-text'>PDF Files</p>
                    </div>
                    <div>
                        <img height={100} src={wordIcon} alt="word" />
                        <p className='icon-text'>Word (.docx)</p>
                    </div>
                    <div>
                        <img height={100} src={ppIcon} alt="power point" />
                        <p className='icon-text'>PowerPoint ( .pptx)</p>
                    </div>
                </div>
                <div className='file-upload-wrapper'>
                    <input className='file-input' type="file" name="hidden" id="hidden" accept='.pdf,.docx,.pptx' onChange={handleFileChange}/>
                    <button className='btn-primary'>Select file from your compurter</button>
                </div>
                <div className="back-to-text-translation ">
                    <button className='btn-secondary' onClick={backToTranslator}>Get back to the translator</button>
                </div>
                </> ) : (
                    <>
                        <p className='upl-file-text'>Uploading your file</p>
                        <div className='upl-file-hor-line'></div>
                        <DotsLoader textColor='#000'></DotsLoader>
                    </>
                )}
            </div>}
            
            {step === 2 && <div className='translate-file-wrapper'>
                { !isFileUploading ? (
                <>
                <div>
                    <img height={100} src={getCurrentFileIcon()} alt="" />
                    <p className='text-step-2'>{file?.name}</p>
                </div>
                <div className='step-2-buttons'>
                    <div 
                        style={{marginBottom: '30px'}}
                        onClick={handleTranslateFile}
                        ><button className='btn-primary'>Translate into {langsMap.get(langTo)}</button></div>
                    <div><button onClick={() => setStep(1)} className='btn-secondary'>Cancel</button></div>
                </div> 
                </>
                ) : (
                    <>
                    <p className='upl-file-text'>Translating your file</p>
                    <div className='upl-file-hor-line'></div>
                    <DotsLoader textColor='#000' ></DotsLoader>
                </>
                )}
            </div>
            }   

            {step === 3 && <div className='translate-file-wrapper'>
                    <div>
                        <img height={100} src={getCurrentFileIcon()} alt="" />
                        <p className='text-step-2'>{file?.name as string}</p>
                    </div>
                    <div className='step-2-buttons'>
                        <div 
                            style={{marginBottom: '30px'}}
                            ><button onClick={handleDownloadFile} className='btn-primary'>Download File</button></div>
                        <div><button onClick={() => setStep(1)} className='btn-secondary'>Translate new file</button></div>
                    </div>
            </div>}

        </div>
    )
}

export default TranslateFiles;