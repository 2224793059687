import './DownloadBtn.scss';
import jsPDF from 'jspdf';
import moment from 'moment';
import { useState } from 'react';
import LoaderSmall from '../loader-small/LoaderSmall';

interface DownloadProps {
    fileContent: string;
    fileName?: string;
}

const DownloadBtn = ({fileContent, fileName}: DownloadProps) => {

    const [isDownloadLoading, setIsDownloadLoading] = useState(false);

    const downloadFile = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const docType = e.target.value;
        if (docType === 'pdf') {
            downloadPdf(fileName || '', fileContent);
            return;
        }
        if (docType === 'txt') {
            downloadTxt(fileName || '', fileContent)
        }
    }

    const downloadPdf = (fileName: string, fileContent: string) => {
        setIsDownloadLoading(true);
        const doc = new jsPDF();
        let margin = 10;
        let lineHeight = 10;
        const lines = doc.splitTextToSize(fileContent, 180);
        const pageHeight = doc.internal.pageSize.getHeight();
        let cursorY = margin;
        lines.forEach((line: string) => {
            if (cursorY + lineHeight > pageHeight - 10) {
                doc.addPage();
                cursorY = margin;
              }
            
              doc.text(line, margin, cursorY);
              cursorY += lineHeight;
        });

        const fName = fileName || 'Translation_' + moment().format("DD/MM/YYYY");
        doc.save(fName + '.pdf');
        setIsDownloadLoading(false);
    }

    const downloadTxt = (fileName: string, fileContent: string) => {
        const blob = new Blob([fileContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        const fName = fileName || 'Translation_' + moment().format("DD/MM/YYYY");
        a.href = url;
        a.download = fName + '.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    return (
        <>
            {
                isDownloadLoading ? ( <LoaderSmall /> ) :
                (
                <select value="default" onChange={downloadFile} className='download-as-btn' name="download-as" id="download-as">
                    <option disabled value="default">Download as</option>
                    <option value="pdf">Download as PDF</option>
                    <option value="txt">Download as Text doc(.txt)</option>
                </select>
                )
            }
   
        </>
    )
}

export default DownloadBtn;