import { Controller, useForm } from "react-hook-form";
import "./PasswordReset.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CheckEmail from "../check-email/CheckEmail";
import { passwordReset } from "../../services/auth-service";
import { toast } from "react-toastify";

interface PasswordResetProps {
  setLoginState: () => void;
}

const PasswordReset = ({ setLoginState }: PasswordResetProps) => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { isValid },
  } = useForm();
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const notify = (message: string) => toast.error(`${message}`, { position: 'top-right' });

  const reset = async (data: any) => {
    try {
      await passwordReset(data?.email);
      setUserEmail(data?.email);
    } catch (err: any) {
      console.log(err);
      notify(err?.response?.data?.message);
      setUserEmail("");
    }
  };

  const onErrors = () => {};

  return (
    <div className="reset-pass-form">
      {userEmail === "" && (
        <div className="reset-pass-title">Password Reset</div>
      )}
      {userEmail === "" ? (
        <form
          style={{ marginTop: "auto", marginBottom: "30px" }}
          onSubmit={handleSubmit(reset, onErrors)}
        >
          <div className="form-fields">
            <div className="email-wrapper">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  minLength: {
                    value: 6,
                    message:
                      "The username/email should contain at least 6 letters.",
                  },
                  pattern: {
                    value: /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/,
                    message: "Please use a valid email",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div style={{ position: "relative" }}>
                    <p className="reset-input-label-text">
                      Put your email and we’ll send to you the magic link *
                    </p>
                    <label className="username-label" htmlFor="email"></label>
                    <input
                      className="email-input"
                      type="email"
                      {...field}
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                    {fieldState.error && (
                      <p className="validation-error-message">
                        {fieldState?.error?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="reset-btn-wrapper">
              <button
                className="form-button"
                type="submit"
                onSubmit={handleSubmit(reset, onErrors)}
              >
                Reset Password
              </button>
            </div>
            <div style={{  width: '100%' }} className="go-to-login-wrapper">
              <p style={{ margin: "auto" }}>
                Go to
                <button className="go-to-login-btn" onClick={setLoginState}>
                  Login
                </button>
              </p>
            </div>
          </div>
        </form>
      ) : (
        <CheckEmail navigateToLogin={setLoginState} userMail={userEmail} title={"Password Reset"}></CheckEmail>
      )}
    </div>
  );
};

export default PasswordReset;
