import "./Footer.scss";
import dortmundLogo from '../../../assets/dortmund-logo-1.svg';
import onlineEngineeringLogo from '../../../assets/online-eng-logo-2.svg';
import motionLogo from '../../../assets/control-motion-logo3.svg';

const Footer = () => {
  return (
    <div className="page-footer">
      <div className="logo-first">
        <img src={dortmundLogo} alt="Logo" />
      </div>
      <div className="logo-second">
        <img src={onlineEngineeringLogo} alt="Logo" />
      </div>
      <div className="logo-third">
        <img src={motionLogo} alt="Logo" />
      </div>
    </div>
  );
};

export default Footer;
