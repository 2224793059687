import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface PasswordToggleProps {
    passwordToggle: boolean;
    setPasswordState: () => void
}

const PasswordToggle = ({passwordToggle, setPasswordState}: PasswordToggleProps) => {
    return (
        <button
        type="button"
        onClick={setPasswordState}
        style={{
          position: "absolute",
          right: "2px",
          bottom: "4px",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }}
      >
        {passwordToggle ? (
          <VisibilityOffIcon style={{color: '#414141'}}></VisibilityOffIcon>
        ) : (
          <RemoveRedEyeIcon style={{color: '#414141'}}></RemoveRedEyeIcon>
        )}
      </button>
    )
}

export default PasswordToggle;