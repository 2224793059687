import { useState } from "react";
import "./SaveTranslationBtn.scss";
import SaveIcon from '@mui/icons-material/Save';
import { toast } from "react-toastify";

interface BtnProps {
  saveTranslationToDB: Function;
}

const SaveTranslationBtn = ({ saveTranslationToDB }: BtnProps) => {
  const [toggleState, setToggleState] = useState('default');
  const [translationName, setTranslationName] = useState("");
  const notify = (message: string) => toast.error(`${message}`, { position: "top-right" });

  const handleSave = () => {
    if (!translationName.trim()) {
      notify('Your translations must have a name');
      return;
    }
    saveTranslationToDB(translationName);
    setToggleState('final')
  };

  return (
    <div className="button-save-to-esd-wrapper">
      {toggleState === 'default' && (
        <div
          onClick={() => setToggleState('input')}
          className="aligned-icons save-to-esd"
        >
          Save to ESD
          <SaveIcon style={{marginLeft: 'auto', marginRight: '0px', color: '#414141'}}></SaveIcon>
        </div>
      )}
      {toggleState === 'input' && (
        <div className="btn-add-translation-title">
          <input
            className="input-add-translation-title"
            type="text"
            placeholder="Translation Name"
            required
            onChange={(e) => setTranslationName(e.target.value)}
          />
          
          <button className="btn-save-translation" onClick={handleSave}>
            <SaveIcon style={{marginLeft: 'auto', marginRight: '0px', color: '#414141'}}></SaveIcon>
          </button>

        </div>
      )}
      
      {toggleState === 'final' &&
        <div onClick={() => setToggleState('default')} className="btn-saved-translation-final">Saved &#10003;</div>
      }

    </div>
  );
};

export default SaveTranslationBtn;
