import './LoaderSmall.scss';

const LoaderSmall = () => {

    return (
        <div className="bouncing-loader-small">
            <div className="dot-small"></div>
            <div className="dot-small"></div>
            <div className="dot-small"></div>
        </div>
    )
}
export default LoaderSmall;