import { useEffect, useRef, useState } from "react";
import "./translator.scss";
import { TranslatorDto } from "../../models/translator.interface";
import { getUsage, sendForTranslationText } from "../../data/deeplapi";
import deleteIcon from "../../assets/icons/delete-icon.svg";
import { useNavigate } from "react-router";
import filesIcon from "../../assets/icons/filesIcon.svg";
import textIcon from "../../assets/icons/textIcon.svg";
import TranslatorHeader from "../../components/translator-header/TranslatorHeader";
import TranslateFiles from "../../components/translate-files/TranslateFiles";
import { DeeplUsage } from "../../models/deepl.interface";
import newTranslationIcon from "../../assets/icons/newTranslationIcon.svg";
import copyIcon from "../../assets/icons/copyIcon.svg";
import HeaderMain from "../../components/layout/footer/header-main/HeaderMain";
import SaveTranslationBtn from "../../components/save-translation-btn/SaveTranslationBtn";
import { useUserData } from "../../hooks/userData";
import { sendTranslationtoDB } from "../../services/file-service";
import DownloadBtn from "../../components/download-btn/DownloadBtn";
import { toast } from "react-toastify";

function Translator() {
  const navigate = useNavigate();

  const notify = (message: string) =>
    toast.error(`${message}`, { position: "top-right" });
  const notifySuccess = (message: string) =>
    toast.success(`${message}`, { position: "top-right" });

  const targetTextareaRef = useRef<HTMLTextAreaElement>(null);
  const sourceTextareaRef = useRef<HTMLTextAreaElement>(null);

  const [sourceText, setSourceText] = useState("");
  const [targetText, setTargetText] = useState("");
  const [srcVal, setSrcVal] = useState("de");
  const [targetVal, setTargetVal] = useState("en-GB");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [sourceDeleteBtnVisible, setSourceDeleteBtnVisible] = useState(false);
  const [targetTextareaReadonly, setTargetTextareaReadonly] = useState(true);
  const [typeTranslation, setTypeTranslation] = useState("translateText");
  const [deeplUsage, setDeeplUsage] = useState<DeeplUsage>();
  const { userData } = useUserData();

  let translateData: TranslatorDto;

  async function handleTranslateClick() {
    //receive event from header comp translate button
    buildTranslateData();
    try {
      const res = await sendForTranslationText(translateData);
      setTargetText(res[0].text);
      checkTargetTextareaSymbols(res[0].text);
      setTargetTextareaReadonly(false);
    } catch (err: any) {
      notify(err?.response?.data?.message);
      console.log(err);
    }
  }

  const checkSourceTextareaSymbols = (val: string) => {
    const sourceTA = document.getElementById("source-textarea");
    if (!sourceTA) return;
    if (val.split("").length > 30) {
      sourceTA.style.fontSize = "22px";
    } else {
      sourceTA.style.fontSize = "26px";
    }
  };

  const checkTargetTextareaSymbols = (val: string) => {
    const sourceTA = document.getElementById("target-textarea");
    if (!sourceTA) return;
    if (val.split("").length > 30) {
      sourceTA.style.fontSize = "22px";
    } else {
      sourceTA.style.fontSize = "26px";
    }
  };

  const handleSourceVal = (e: React.FormEvent<HTMLSelectElement>) => {
    //checks in header component for select source language value
    setSrcVal(e.currentTarget.value);
  };

  const handleTargetVal = (e: React.FormEvent<HTMLSelectElement>) => {
    //checks in header component for select target language value
    setTargetVal(e.currentTarget.value);
  };

  const handleSourceTextChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    checkSourceTextareaSymbols(event.currentTarget.value);
    setSourceText(event.currentTarget.value);
    toggleDeleteSourceButton(event.currentTarget.value);
  };

  function toggleDeleteSourceButton(currentText: string) {
    // check if input available and set button state also delete target textarea text if no input text available
    if (currentText === "") {
      setTargetText("");
    }
    if (currentText) {
      setSourceDeleteBtnVisible(true);
    } else {
      setSourceDeleteBtnVisible(false);
    }
  }

  function copyToClipboardHandler() {
    navigator.clipboard.writeText(targetText);
    notifySuccess("Translation copied successfully");
  }

  const handleTargetTextChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    checkTargetTextareaSymbols(event.currentTarget.value);
    setTargetText(event.currentTarget.value);
    if (event.currentTarget.value === "") {
      setTargetTextareaReadonly(true);
    } else {
      setTargetTextareaReadonly(false);
    }
  };

  function buildTranslateData(): void {
    translateData = {
      target_lang: targetVal,
      text: [sourceText],
    };
  }

  function hasSourceText(textValue: string) {
    //enable button translate in header comp if text for transl available
    if (textValue) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }

  async function getDeeplUsage() {
    try {
      const usage = await getUsage();
      setDeeplUsage(usage);
      return usage;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getDeeplUsage();
  }, []);

  useEffect(() => {
    //track changes for source text and value in select
    hasSourceText(sourceText);
  }, [sourceText, targetVal]);

  function clearTargetText() {
    //delete target btn handler
    setTargetTextareaReadonly(true);
    setTargetText("");
  }

  function clearSourceText() {
    //delete source btn handler
    setSourceDeleteBtnVisible(false);
    setSourceText("");
    clearTargetText();
  }

  const onTranslateFilesClick = () => {
    setTypeTranslation("translateFiles");
  };

  const onTranslateTextClick = () => {
    setTypeTranslation("translateText");
  };

  const saveTranslationToDB = async (fileName: string) => {
    const fileData = {
      fileName: fileName,
      fileContent: targetText,
      userId: userData?.userId,
      userEmail: userData?.userEmail,
    };
    await sendTranslationtoDB(fileData);
    notifySuccess("Successfully saved translation");
  };

  return (
    <div className="translate-page">
      <HeaderMain></HeaderMain>
      <div className="translator-page-content">
        <div className="translator-top-actions">
          <div
            onClick={onTranslateTextClick}
            className={
              typeTranslation === "translateText"
                ? "translate-text-actions-wrapper active"
                : "translate-text-actions-wrapper"
            }
          >
            <div className="actions-icon">
              <img width={24} height={24} src={textIcon} alt="Text Icon" />
            </div>
            <div className="actions-text">
              <p>Translate text</p>
              <p>32 languages</p>
            </div>
          </div>
          <div
            onClick={onTranslateFilesClick}
            className={
              typeTranslation === "translateFiles"
                ? "translate-files-actions-wrapper active"
                : "translate-files-actions-wrapper"
            }
          >
            <div className="actions-icon">
              <img width={24} height={24} src={filesIcon} alt="Files Icon" />
            </div>
            <div className="actions-text">
              <p>Translate FIles</p>
              <p>.pdf, .docx, .pptx</p>
            </div>
          </div>
        </div>
        <div className="translator-wrapper">
          <TranslatorHeader
            handleSourceVal={handleSourceVal}
            handleTargetVal={handleTargetVal}
          />
          {typeTranslation === "translateText" && (
            <div className="translate-text-wrapper">
              <div className="translator-elements">
                <div className="source-textarea">
                  <textarea
                    ref={sourceTextareaRef}
                    style={{pointerEvents: 'auto'}}
                    className="textarea-no-default-styles"
                    name="source-textarea"
                    id="source-textarea"
                    value={sourceText}
                    onChange={handleSourceTextChange}
                  ></textarea>
                  { !sourceText && <div onClick={() => sourceTextareaRef.current?.focus()} className="textarea-empty-info-text-wrapper">
                    <p style={{fontSize: '26px', pointerEvents: 'none', margin: 0}}>Type to translate</p>
                    <p style={{fontSize: '20px', pointerEvents: 'none', marginTop: '20px'}}>Or drag and drop to translate PDF .pdf,WORD .docx,  PowerPoint.pptx with our 
                      <span 
                      onClick={() => setTypeTranslation("translateFiles")} 
                      style={{textDecoration: 'underline', color: '#A2BFF8', cursor: 'pointer', pointerEvents: 'auto' }}
                      >  document translator</span>
                    </p>
                  </div>}
            
                    <div className="source-actions-wrapper">
                    {sourceText && (
                      <>
                      <div className="usage-info">
                      {deeplUsage?.character_count}/{deeplUsage?.character_limit}{" "}
                      Characters
                      </div>
                      <button
                        disabled={!isButtonActive}
                        onClick={handleTranslateClick}
                        className="translate-btn btn-primary"
                      >
                      Translate
                      </button>
                      </>
                        )}
                    </div>
                
                  
                  {sourceDeleteBtnVisible ? (
                    <div
                      className="delete-button-source"
                      onClick={clearSourceText}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete"
                        width="24px"
                        height="24px"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="vertical-line"></div>
                <div className="target-textarea">
                  <textarea
                    ref={targetTextareaRef}
                    readOnly={targetTextareaReadonly}
                    className="textarea-no-default-styles"
                    name="target-textarea"
                    id="target-textarea"
                    value={targetText}
                    onChange={handleTargetTextChange}
                  ></textarea>
                  <div className="target-actions-wrapper">
                    {!targetTextareaReadonly ? (
                      <>
                      <div
                        onClick={clearSourceText}
                        className="aligned-icons new-translation"
                      >
                        <img
                          style={{ marginRight: "10px", marginTop: "2px" }}
                          src={newTranslationIcon}
                          alt="new translation icon"
                        />
                        New translation
                      </div>
                      <div
                        onClick={copyToClipboardHandler}
                        className="aligned-icons copy"
                      >
                        <img src={copyIcon} alt="copy" />
                        <span className="tooltip-text" id="top">
                          Copy translation
                        </span>
                      </div>
                      <DownloadBtn fileContent={targetText}></DownloadBtn>
                      <SaveTranslationBtn
                        saveTranslationToDB={saveTranslationToDB}
                      ></SaveTranslationBtn>
                    
                    </>
                  ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
          {typeTranslation === "translateFiles" && (
            <div className="translate-files-wrapper">
              <TranslateFiles
                langFrom={srcVal}
                langTo={targetVal}
                backToTranslator={() => setTypeTranslation("translateText")}
              ></TranslateFiles>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Translator;
