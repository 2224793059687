import Footer from "../../components/layout/footer/Footer";
import "./ConfirmRegistration.scss";
import thumbUpIcon from "../../assets/icons/thumbUpIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { confirmRegistration } from "../../services/auth-service";

const ConfirmRegistration = () => {
  const navigate = useNavigate();
  const params = useParams();

  const checkForConfirmation = async (userCode: string) => {
    try {
      const confirmed = await confirmRegistration(userCode);
    } catch (err) {
      console.log(err);
      navigate('/login');
    }
  };

  useEffect(() => {
    if (params?.userCode) {
      checkForConfirmation(params?.userCode);
    }
  }, []);

  return (
    <div className="confirmed-reg-page">
      <img
        className="overlay-bg"
        src={require("../../assets/deepl-new-background.webp")}
        alt="overlay"
      />
          <div className="confirmed-reg-content-wrapper">
            <div className="page-title">
              <div className="company">ESD</div>
              <div className="title-text">TRANSLATION</div>
              <div className="title-text">PLATFORM</div>
            </div>
            <div className="success-confirmed-reg-wrapper">
              <div className="create-password-title">SUCCESS</div>
              <div className="success-confirmed-reg">
                <img src={thumbUpIcon} width={250} alt="Thumb up icon" />
                <p className="success-confirmed-text">Congratulations, your account has been successfully created </p>
                <div className="go-to-login-wrapper">
                  Go to
                  <button
                    type="button"
                    className="go-to-login-btn"
                    onClick={() => navigate("/login")}
                  >
                     Login
                  </button>
                </div>
           
              </div>
            </div>
          </div>
          <Footer></Footer>
    </div>
  );
};

export default ConfirmRegistration;
