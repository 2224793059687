import "./login.scss";
import { authenticate } from "../../services/auth-service";
import { useNavigate, useLocation } from "react-router";
import React, { useEffect, useState } from "react";
import { FormState } from "./form-state.enum";
import { Controller, useForm } from "react-hook-form";
import PasswordReset from "../../components/password-reset/PasswordReset";
import DotsLoader from "../../components/dots-loader/DotsLoader";
import Footer from "../../components/layout/footer/Footer";
import { useUserData } from "../../hooks/userData";
import DownloadSharableFile from "../../components/download-sharable-file/DownloadSharableFile";
import { toast } from "react-toastify";
import PasswordToggle from "../../components/password-toggle/PasswordToggle";
import { PasswordTooltip } from "../../components/password-tooltip/PasswordTooltip";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [formState, setFormState] = useState(FormState.login);
  const [isLoading, setIsLoading] = useState(false);
  const [fileId, setFileId] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [loginError, setLoginError] = useState("");
  const { loginUser } = useUserData();

  const notify = (message: string) =>
    toast.error(`${message}`, { position: "top-right" });
  const notifySuccess = (message: string) =>
    toast.success(`${message}`, { position: "top-right" });

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm();

  useEffect(() => {
    if (location?.search) {
      const urlParams = location?.search?.replace("?", "");
      const decodedData = JSON.parse(window.atob(urlParams));
      const fileId = decodedData.fileId;
      setFileId(fileId);
    }
  }, []);

  const login = async (data: { [key: string]: any }) => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const res = await authenticate(data.usernameOrEmail, data.password);
        const isAuth = !!res?.data?.isAuthenticated;
        if (!isAuth) {
          return;
        }
        notifySuccess(res?.data?.message);
        const dateNow = new Date();
        const datePlus30min = new Date(dateNow.getTime() + 30 * 60 * 1000);
        const responseData = { ...res.data, sessionExpiry: datePlus30min };
        const userData = JSON.stringify(responseData);
        localStorage.setItem("user", userData);
        setContextUserData(responseData);
        setLoginError("");
        setIsLoading(false);
        if (fileId) {
          setFormState(FormState.sharableFile);
        } else {
          navigate("/");
        }
      } catch (err: any) {
        console.log(err?.response?.data?.message);
        notify(err?.response?.data?.message);
        setLoginError(err?.response?.data?.message);
        setIsLoading(false);
        navigate("/login");
      }
    }, 2000);
  };

  const setContextUserData = (data: { [key: string]: any }) => {
    const userData = {
      userId: data.userId,
      userEmail: data.userEmail,
      message: data.message,
      isAuthenticated: data.isAuthenticated,
      role: data.role,
      sessionExpiry: data.sessionExpiry,
    };
    loginUser(userData);
  };

  const setLoginState = () => {
    setFormState(FormState.login);
  };

  const setPasswordToggleState = () => {
    setPasswordToggle((val) => !val);
  }

  function onErrors(errors: any) {
    // console.log(errors);
  }
  return (
    <div className="login-wrapper">
      <img
        className="overlay-bg"
        src={require("../../assets/deepl-new-background.webp")}
        alt="overlay"
      />
      <div className="login-content-wrapper">
        <div className="page-title">
          <div className="company">ESD</div>
          <div className="title-text">TRANSLATION</div>
          <div className="title-text">PLATFORM</div>
        </div>
        {formState === FormState.login && (
          <div className="login-form">
            {!isLoading && (
              <form onSubmit={handleSubmit(login, onErrors)}>
                <div className="form-fields">
                  <div className="title">SIGN IN</div>
                  <div className="usernameOrEmail-wrapper">
                    <Controller
                      name="usernameOrEmail"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <p className="login-input-label-text">
                            Username or email *
                          </p>
                          <label
                            className="username-label"
                            htmlFor="text"
                          ></label>
                          <input
                            className="username-or-email-input"
                            type="text"
                            {...field}
                            name="usernameOrEmail"
                            id="usernameOrEmail"
                            placeholder="Username or email"
                          />
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className="login-password-wrapper">
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                        minLength: {
                          value: 6,
                          message:
                            "The password should contain at least 6 characters.",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <p className="login-input-label-text">
                            Password *</p>
                          <div style={{ position: "relative" }}>
                            <label
                              className="login-password-label"
                              htmlFor="password"
                            ></label>
                            <input
                              className="login-password-input"
                              {...field}
                              type={passwordToggle ? "text" : "password"}
                              name="password"
                              id="password"
                              placeholder="Password"
                            />
                            <PasswordToggle passwordToggle={passwordToggle} setPasswordState={setPasswordToggleState} />
                          </div>

                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="login-btn-wrapper">
                    <button
                      className="form-button"
                      type="submit"
                      onSubmit={handleSubmit(login, onErrors)}
                    >
                      Log In
                    </button>
                  </div>

                  <p className="no-accout-par">
                    Don't have an account? You can{" "}
                    <button
                      className="sign-up-btn"
                      onClick={() => navigate("/signup")}
                    >
                      Sign up
                    </button>
                  </p>
                  <p style={{ margin: "auto" }}>
                    <button
                      className="forgot-pass-btn"
                      onClick={() => setFormState(FormState.createPassword)}
                    >
                      Forgot password?
                    </button>
                  </p>
                </div>
              </form>
            )}
            {isLoading && (
              <div className="sign-in-loading-wrapper">
                <div className="title">SINGING IN...</div>
                <div style={{ marginTop: "100px" }}>
                  <DotsLoader textColor="#FFFFFF"></DotsLoader>
                </div>
              </div>
            )}
          </div>
        )}
        {formState === FormState.createPassword && (
          <PasswordReset setLoginState={setLoginState}></PasswordReset>
        )}
        {formState === FormState.sharableFile && (
          <DownloadSharableFile fileId={fileId}></DownloadSharableFile>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Login;
