import './DownloadSharableFile.scss';
import downloadFileImg from '../../assets/downloadFileImg.svg';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import { useState } from 'react';
import { getSharableFile } from '../../services/file-service';
import LoaderSmall from '../loader-small/LoaderSmall';
import { Translation } from '../../pages/translator-dashboard/TranslatorDashboard';
import { toast } from 'react-toastify';

interface DownloadShFile {
    fileId: string;
}

const DownloadSharableFile = ({fileId}: DownloadShFile) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const notify = (message: string) => toast.error(`${message}`, { position: 'top-right' });

    const downloadFile = async () => {
        setIsLoading(true)
        try {
            const res = await getSharableFile(fileId);
            const fileData: any = res?.data?.result; 
            const doc = new jsPDF();
            let margin = 10;
            let lineHeight = 10;
            const lines = doc.splitTextToSize(fileData?.file, 180);
            const pageHeight = doc.internal.pageSize.getHeight();
            let cursorY = margin;
            lines.forEach((line: string) => {
                if (cursorY + lineHeight > pageHeight - 10) {
                    doc.addPage();
                    cursorY = margin;
                  }
                
                  doc.text(line, margin, cursorY);
                  cursorY += lineHeight;
            });
            const fName = fileData?.file_name;
            doc.save(fName + '.pdf');
        } catch(err) {
            console.log(err);
            notify('An error occured while downloading your file')
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className='download-wrapper'>
            <div className='download-file-title'>GRAB YOUR FILE</div>
            <div className='download-file-img'>
                <img src={downloadFileImg} alt="Download file img" />
            </div>
            <p className="download-file-info">Your file is ready for download. Click the button below to start</p>
            <div>
                { isLoading ? ( 
                    <LoaderSmall></LoaderSmall>
                ) : (
                    <button onClick={downloadFile} className='form-button download'>Download File</button>
                )}
            </div>
            <div className='go-to-login-wrapper go-to-dashboard'>
                Go to   
                <button onClick={() => navigate('/dashboard')} className='btn-secondary download'>Dahsboard</button>
            </div>
        </div>
    )
}

export default DownloadSharableFile;