import { ChangeEvent, useEffect, useState } from "react";
import HeaderMain from "../../components/layout/footer/header-main/HeaderMain";
import "./TranslatorDashboard.scss";
import { DeeplUsage } from "../../models/deepl.interface";
import { getUsage } from "../../data/deeplapi";
import DownloadBtn from "../../components/download-btn/DownloadBtn";
import shareIcon from "../../assets/icons/shareIcon.svg";
import { deleteFile, getUserTranslations } from "../../services/file-service";
import { useUserData } from "../../hooks/userData";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Pagination, Stack } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from "../../components/confirmation-dialog/ConfirmationDialog";

export interface Translation {
  createdAt: Date;
  file: string;
  file_name: string;
  id: string;
  updatedAt: Date;
  user_email: string;
  user_id: string;
}

const TranslatorDashboard = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState("user");
  const [deeplUsage, setDeeplUsage] = useState<DeeplUsage>();
  const [barPercentage, setBarPercentage] = useState<number>(0);
  const [translations, setTranslations] = useState<Translation[]>([]);
  const { userData } = useUserData();
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const indexOfLastTranslation = page * pageSize;
  const indexOfFirstTranslation = indexOfLastTranslation - pageSize;
  const currentTranslations = translations?.slice(indexOfFirstTranslation, indexOfLastTranslation);
  const notifySuccess = (message: string) => toast.success(`${message}`, { position: 'top-right' });
  const notifyError = (message: string) => toast.error(`${message}`, { position: 'top-right' });
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [currTranslationToDelete, setCurrTranslationToDelete] = useState<Translation | null>(null);
  const message = 'Do you want to delete this translation?';

  useEffect(() => {
    setRole(userData?.role || "user");
    getDeeplUsage();
    getTranslations();
  }, []);

  const getTranslations = async () => {
    try {
      const result = await getUserTranslations(userData?.userId || "");
      setTranslations(result.data.result);
    } catch (err) {
      console.log(err);
    }
  };

  async function getDeeplUsage() {
    try {
      const usage: DeeplUsage = await getUsage();
      usage.character_used = usage.character_limit - usage.character_count;
      setDeeplUsage(usage);
      setBarPercentage((usage.character_count / usage.character_limit) * 100);
    } catch (err) {
      console.log(err);
    }
  }

  const copyUrlToClipboard = (fileId: string) => {
    const fileData = { fileId: fileId };
    const encodedFileId = window.btoa(JSON.stringify(fileData));
    const url = `${process.env.REACT_APP_CLIENT_URL}/login?${encodedFileId}`;
    navigator.clipboard.writeText(url);
    notifySuccess("Link copied successfully");
  };

  const handlePageChange = (event: ChangeEvent<unknown>, pg: number) => {
    setPage(pg);
  };

  const handleDeleteTranslation = (translation: Translation) => {
    setConfirmationDialogOpen(true);
    setCurrTranslationToDelete(translation);
  };

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete) {
      try {
        await deleteFile(currTranslationToDelete?.id || '');
        notifySuccess("Translation deleted successfully");
        const index = translations.findIndex((el) => el.id === currTranslationToDelete?.id);
        translations.splice(index, 1);
        
      }
      catch(err) {
        console.log(err);
        notifyError('An error occured while deleting your translation');
      } 
    }
    setConfirmationDialogOpen(false);
    setCurrTranslationToDelete(null);
  }

  return (
    <div className="translator-dashboard-page">
      <HeaderMain></HeaderMain>
      <div className="translator-dashboard-content">
        {role === "admin" && (
          <div className="usage-dashboard">
            <div className="char-usage-container">Character usage</div>
            <div className="char-usage-graph-wrapper">
              <div className="ch-used">{deeplUsage?.character_count}</div>
              <div className="usage-bar-wrapper">
                <div
                  style={{ width: barPercentage + "%" }}
                  className="usage-bar"
                >
                  <span>{barPercentage.toFixed(0) + "%"}</span>
                </div>
              </div>
              <div className="usage-graph-bottom">
                <div className="limit-char">
                  out of {deeplUsage?.character_limit} free characters
                </div>
                <div className="ch-left">
                  {deeplUsage?.character_used} free characters left
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="translated-files-wrapper">
          <ConfirmationDialog open={confirmationDialogOpen} handleClose={handleDialogClose} descMessage={message}></ConfirmationDialog>
          <div className="translated-left">
            <div>
              <p className="translated-left-title">Translated files</p>
              <p className="translated-left-files-count">
                {translations?.length} translated files so far
              </p>
            </div>
            <div>
              <button
                onClick={() => navigate("/")}
                className="btn-primary new-tr-dashboard-btn"
              >
                Make a new translation
              </button>
            </div>
          </div>
          <div className="v-line"></div>
          <div className="translated-right">
            <table className="file-data-table">
              <thead>
                <tr>
                  <th className="first-column">File name</th>
                  <th>Created at</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentTranslations?.map((translation) => {
                  return (
                    <tr key={translation.id}>
                      <td className="first-column">{translation?.file_name}</td>
                      <td className="second-column">
                        {moment(translation?.createdAt).format("DD/MM/YYYY") + ', ' + moment(translation?.createdAt).format("HH:mm:ss")}
                      </td>
                      <td className="third-column">
                        <a
                          className="copy"
                          onClick={() => copyUrlToClipboard(translation?.id)}
                        >
                          <img src={shareIcon} alt="share" />
                          <span className="copy-url-text" id="top-tooltip">
                            Copy Url
                          </span>
                        </a>
                      </td>
                      <td className="fourth-column">
                        <DownloadBtn
                          fileContent={translation?.file}
                          fileName={translation?.file_name}
                        />
                      </td>
                      <td className="fifth-column">
                        <button style={{ border: 'none', background: '#FFF' }} onClick={() => handleDeleteTranslation(translation)}>
                          <DeleteIcon style={{color: '#FF9898', cursor: 'pointer'}}></DeleteIcon>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Stack
              spacing={2}
              sx={{
                flexWrap: "wrap",
                alignContent: "center",
                marginBottom: "40px",
                display:
                translations?.length > pageSize
                    ? "flex"
                    : "none",
              }}
            >
              <Pagination
                count={Math.ceil(
                    translations?.length / pageSize
                )}
                shape="rounded"
                page={page}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranslatorDashboard;
