import './HeaderMain.scss';
import dashboardIcon from '../../../../assets/icons/dashboardIcon.svg';
import logoutIcon from '../../../../assets/icons/logoutIcon.svg';
import { useNavigate } from 'react-router-dom';

const HeaderMain = () => {
    const navigate = useNavigate();

    function logout() {
        localStorage.removeItem("user");
        navigate("/login");
    }

    return (
        <div className='header-main-wrapper'>
            <div className='header-main-container'>
            <div onClick={() => navigate('/')} className='header-main-title'>
                <span className='title-main-bold'>ESD</span>
                <span className='title-main-standard'>TRANSLATOR</span>
            </div>
            <div className='header-main-actions'>
                <div onClick={() => navigate('/dashboard')} className='dashboard-wrapper'>
                    <img src={dashboardIcon} alt="Dashboard icon" />
                    <span>Dashboard</span>
                </div>
                <div className='logout-wrapper' onClick={logout}>
                    <img src={logoutIcon} alt="Logout icon" />
                    <span className='log-out'>Log out</span>
                </div>
            </div>
            </div>
        </div>
    )
}

export default HeaderMain;