import { ErrorResponse, useNavigate } from "react-router-dom";
import Footer from "../../components/layout/footer/Footer";
import "./Signup.scss";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import CheckEmail from "../../components/check-email/CheckEmail";
import { signup } from "../../services/auth-service";
import { toast } from "react-toastify";
import PasswordToggle from "../../components/password-toggle/PasswordToggle";
import { PasswordTooltip } from "../../components/password-tooltip/PasswordTooltip";
import InfoIcon from '@mui/icons-material/Info';


const Signup = () => {
  const {
    watch,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();
  const notify = (message: string) => toast.error(`${message}`, { position: 'top-right' });
  const navigate = useNavigate();
  let pwd = watch("password");
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  const [userMail, setUserMail] = useState("");
  const privPolicy = watch('privPolicy', false);
  const checkboxTerms = watch('termsOfUse', false);

  const formSignup = async (data: any) => {
    try {
      const res = await signup(data);
      if (res?.data?.userCreated) {
        setUserMail(data?.email);
      }
      
    } catch (err: any) {
      console.log(err?.response?.data?.message);
      notify(err?.response?.data?.message);
      setUserMail("");
    }
  };

  const onErrors = (errors: any) => {
    // console.log(errors)
  }

  const setPasswordToggleState = () => {
    setPasswordToggle((val) => !val);
  }

  const setConfirmPasswordToggleState = () => {
    setConfirmPasswordToggle((val) => !val);
  }

  return (
    <div className="signup-page">
      <img
        className="overlay-bg"
        src={require("../../assets/deepl-new-background.webp")}
        alt="overlay"
      />
      <div className="signup-content">
        <div className="signup-left-col">
          <div className="signup-page-title">
            <div className="company">ESD</div>
            <div className="title-text">TRANSLATION</div>
            <div className="title-text">PLATFORM</div>
          </div>
          <div className="reg-info-1">
            <p className="step">1. Register your name and company</p>
            <p className="step-description">
              Enter your personal details including name, email, and contact
              number. Choose a username and create a strong password.
            </p>
          </div>
          <div className="reg-info-2">
            <p className="step">
              2. Check and sign term of use in your account
            </p>
            <p className="step-description">
              You will receive a registration email. Please check and confirm
              your registration.
            </p>
          </div>
          <div className="footer-signup">
            <Footer></Footer>
          </div>
        </div>
        {!userMail ? (
          <div className="signup-right-col">
            <div className="signup-form-title">REGISTRATION</div>
            <div className="signup-form">
              <form onSubmit={handleSubmit(formSignup, onErrors)}>
                <div className="signup-form-fields">
                  <div className="signup-base-field-wrapper">
                    <Controller
                      name="userName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <label
                            className="signup-label-base"
                            htmlFor="userName"
                          >
                            Username *
                          </label>
                          <input
                            className="signup-input-base"
                            type="text"
                            {...field}
                            name="userName"
                            id="userName"
                            placeholder="Enter username"
                          />
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                        pattern: {
                          value:
                            /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/,
                          message: "Please use a valid email",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <label className="signup-label-base" htmlFor="email">
                            Email *
                          </label>
                          <input
                            className="signup-input-base"
                            type="email"
                            {...field}
                            name="email"
                            id="email"
                            placeholder="Enter email"
                          />
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className="signup-base-field-wrapper">
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <label
                            className="signup-label-base"
                            htmlFor="firstName"
                          >
                            First name *
                          </label>
                          <input
                            className="signup-input-base"
                            type="text"
                            {...field}
                            name="firstName"
                            id="firstName"
                            placeholder="Enter first name"
                          />
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <label
                            className="signup-label-base"
                            htmlFor="lastName"
                          >
                            Last name *
                          </label>
                          <input
                            className="signup-input-base"
                            type="text"
                            {...field}
                            name="lastName"
                            id="lastName"
                            placeholder="Enter last name"
                          />
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div
                    className="signup-base-field-wrapper"
                  >
    
                    <Controller
                      name="company"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <label
                            className="signup-label-base"
                            htmlFor="company"
                          >
                            Company *
                          </label>
                          <input
                            className="signup-input-base"
                            type="text"
                            {...field}
                            name="company"
                            id="company"
                            placeholder="Enter company"
                          />
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                        minLength: {
                          value: 5,
                          message: 'Please type your phone number'
                        },
                        pattern: {
                          value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                          message: 'The value should be a valid phone number'
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <label
                            className="signup-label-base"
                            htmlFor="phoneNumber"
                          >
                            Phone number *
                          </label>
                          <PhoneInput
                            inputStyle={{
                              borderBottomRightRadius: '10px',
                              minWidth: '280px',
                              backgroundColor: '#D9D9D9',
                              height: '40px',
                              borderTopRightRadius: '10px'
                            }}
                            defaultCountry="de"
                            required
                            autoFocus
                            placeholder="Phone number"
                            {...field}
                          />
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className="signup-base-field-wrapper">
                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                        minLength: {
                          value: 6,
                          message:
                            "The password should contain at least 6 characters.",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
                          <label
                            className="signup-label-base"
                            htmlFor="password"
                          >
                            Password *
                            <PasswordTooltip
                             title={
                              <React.Fragment>
                                “To create a valid password, you should use at least 6 characters.”
                              </React.Fragment>
                            }
                          >
                            <InfoIcon style={{cursor: 'pointer'}}></InfoIcon>
                          </PasswordTooltip>
                          </label>
                          <input
                            className="signup-password"
                            type={!passwordToggle ? "password" : 'text'}
                            {...field}
                            name="password"
                            id="password"
                          />
                          <PasswordToggle passwordToggle={passwordToggle} setPasswordState={setPasswordToggleState} />
                          </div>
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name="confirmPassword"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                        minLength: {
                          value: 6,
                          message:
                            "The password should contain at least 6 characters.",
                        },
                        validate: (value) =>
                          value === pwd || "The passwords do not match",
                      }}
                      render={({ field, fieldState }) => (
                        <div className="signup-field-wrapper">
                          <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
                          <label
                            className="signup-label-base"
                            htmlFor="confirmPassword"
                          >
                            Confirm Password *
                          </label>
                          <input
                            className="signup-password"
                            {...field}
                            type={!confirmPasswordToggle ? "password" : "text"}
                            name="confirmPassword"
                            id="confirmPassword"
                          />
                           <PasswordToggle passwordToggle={confirmPasswordToggle} setPasswordState={setConfirmPasswordToggleState} />
                          </div>
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="signup-confirmation-chboxes">
                    <Controller
                      name="privPolicy"
                      control={control}
                      rules={{
                        required: "This field is required!"
                      }}
                      render={({ field, fieldState }) => (
                        <div className="priv-policy-wrapper">
                          <input
                            type="checkbox"
                            id="privPolicy"
                            {...field}
                            required
                            onInvalid={() => ''}
                          />
                          <label htmlFor="privPolicy">
                            I have read the Privacy Policy and consent to the
                            collection, storage and processing of my personal
                            data to the extent described above. *
                          </label>
                          {fieldState.error && (
                            <p className="validation-error-message">
                              {fieldState?.error?.message}
                            </p>
                          )}
                        </div>
                      )}
                    />

                    <Controller
                      name="termsOfUse"
                      control={control}
                      rules={{
                        required: "This field is required!"
                      }}
                      render={({ field, fieldState }) => (
                        <div className="terms-of-use-wrapper">
                          <input
                            type="checkbox"
                            id="termsOfUse"
                            {...field}
                            required
                            onInvalid={() => ''}
                          />
                          <label htmlFor="termsOfUse">
                            Here by I confirm that I have read and agree to the
                            following Terms of use. *
                          </label>
                          <p className="validation-error-message">
                            {fieldState?.error?.message}
                          </p>
                        </div>
                      )}
                    />
                  </div>
                  <div className="signup-button-wrapper">
                    <button
                      className={!privPolicy || !checkboxTerms ? "form-button btn-disabled" : "form-button"}
                      type="submit"
                      disabled={privPolicy === false || checkboxTerms === false}
                      onClick={handleSubmit(formSignup)}
                    >Sign up</button>
                  </div>
                </div>
              </form>
              <p className="login-par">
                Go to
                <button
                  className="go-to-login-btn"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
              </p>
            </div>
          </div>
        ) : (
          <div className="signup-success-wrapper">
            <CheckEmail navigateToLogin={() => navigate('/login')} userMail={userMail} title={"REGISTRATION"} succesText={'Please check your email and confirm your registration.'}></CheckEmail>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
