import axios from "axios";
import { Translation } from "../pages/translator-dashboard/TranslatorDashboard";

export const sendTranslationtoDB = async (data: any) => {
    return await axios.post(process.env.REACT_APP_URL + '/translation-save', data, {withCredentials: true});
}

export const getUserTranslations = async (userId: string) => {
    return await axios.get(`${process.env.REACT_APP_URL}/translation-files/${userId}`, {withCredentials: true});
}

export const getSharableFile = async (fileId: string) => {
    return await axios.get(`${process.env.REACT_APP_URL}/file/${fileId}`, {withCredentials: true});
}

export const deleteFile = async (fileId: string) => {
    return await axios.post(`${process.env.REACT_APP_URL}/delete-file`, {
        fileId: fileId
    }, {withCredentials: true});
}