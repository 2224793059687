import axios from "axios";
import {
  DeeplTextResponse,
  TranslatorDto,
} from "../models/translator.interface";

export const langsMap = new Map<string, string>([
  ["ar", "Arabic"],
  ["bg", "Bulgarian"],
  ["da", "Danish"],
  ["cs", "Czech"],
  ["de", "German"],
  ["el", "Greek"],
  ["en-GB", "English"],
  ["en-US", "English (America)"],
  ["es", "Spanish"],
  ["et", "Estonian"],
  ["fi", "Finnish"],
  ["fr", "French"],
  ["hu", "Hungarian"],
  ["id", "Indonesian"],
  ["it", "Italian"],
  ["ja", "Japanese"],
  ["ko", "Korean"],
  ["lt", "Lithuanian"],
  ["lv", "Latvian"],
  ["nb", "Norwegian"],
  ["nl", "Dutch"],
  ["pl", "Polish"],
  ["pt-BR", "Portuguese (Brasilian)"],
  ["pt-PT", "Portuguese"],
  ["ro", "Romanian"],
  ["ru", "Russian"],
  ["sk", "Slovak"],
  ["sl", "Slovenian"],
  ["sv", "Swedish"],
  ["tr", "Turkish"],
  ["uk", "Ukrainian"],
  ["zh", "Chinese"],
]);

export async function sendForTranslationText(
  translationData: TranslatorDto
): Promise<DeeplTextResponse[]> {
  const res = await axios.post(
    process.env.REACT_APP_URL + '/translation-text' || "",
    translationData,
    { withCredentials: true }
  );
  return res.data;
}

export async function sendForTranslationDoc(
  translationData: FormData
): Promise<any> {

  const res = await axios.post(
    process.env.REACT_APP_URL + '/translation-doc' || "",
    translationData,
    {
      withCredentials: true,
    }
  );
  return res;
}

export async function getUsage(): Promise<any> {

  const res = await axios.get(
    process.env.REACT_APP_URL + '/usage' || "",
    {
      withCredentials: true,
    }
  );
  return res.data;
}

export async function downloadFileFromDeepl(
  fileName: string
): Promise<any> {

  const res = await axios.post(
    process.env.REACT_APP_URL + '/download-file' || "",
    {fileName: fileName},
    {
      withCredentials: true,
      responseType: 'blob'
    }
  );
  const url = window.URL.createObjectURL(res.data);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
  return res.data;
}
